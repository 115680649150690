import { createContext, useContext, useEffect, useState } from "react";
import { useTab } from "../../store/useTab";
import { CouponsTabs } from "./CouponsTabs";
interface TabProps {
  currentTab: string;
  setCurrentTab: (tab: string) => void;
}
const TabContext = createContext({} as TabProps);

export function Coupons() {
  const setTab = useTab((store) => store.setTab);
  const [currentTab, setCurrentTab] = useState<string>("tab1");

  useEffect(() => {
    setTab("Cupons");
  }, []);
  return (
    <TabContext.Provider value={{ currentTab, setCurrentTab }}>
      <h1 className="text-3xl font-medium text-zinc-900 dark:text-zinc-100">
        Cupons
      </h1>
      <CouponsTabs />
    </TabContext.Provider>
  );
}

export const useTabContext = () => useContext(TabContext);
