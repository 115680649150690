export function Logo() {
  return (
    <strong className="mx-1 flex items-center gap-2 text-xl font-semibold text-zinc-900 dark:text-zinc-100">
      <svg
        width={30}
        height={30}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={15} cy={15} r={15} fill="url(#paint0_linear_94_6)" />
        <g filter="url(#filter0_d_94_6)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.95 14.888c0-6.593 5.345-11.937 11.938-11.937 2.78 0 5.339.95 7.368 2.544a10.696 10.696 0 00-5.22-1.35c-5.933 0-10.743 4.81-10.743 10.743 0 5.934 4.81 10.744 10.744 10.744 1.894 0 3.674-.49 5.219-1.35a11.886 11.886 0 01-7.368 2.544c-6.593 0-11.937-5.345-11.937-11.938zm24.08 0a9.55 9.55 0 01-9.55 9.55c-2.223 0-4.27-.76-5.893-2.035a8.595 8.595 0 100-15.029 9.509 9.509 0 015.894-2.036 9.55 9.55 0 019.55 9.55zM10.27 9.462l3.35 6.684v4.168h2.63v-4.023l3.462-6.829h-2.758l-1.971 4.152-1.924-4.152H10.27z"
            fill="#3D3935"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_94_6"
            x={1.95081}
            y={1.95082}
            width={26.08}
            height={25.8747}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={0.5} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_94_6" />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_94_6"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_94_6"
            x1={15}
            y1={0}
            x2={15}
            y2={30}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F2F2F2" />
            <stop offset={1} stopColor="#D0D0D0" />
          </linearGradient>
        </defs>
      </svg>
      <span className="sr-only lg:not-sr-only">YazControl</span>
    </strong>
  )
}
