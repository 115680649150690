import { ChangeEvent, InputHTMLAttributes } from "react";
import { useFileInput } from "./Root";

export interface ControlProps extends InputHTMLAttributes<HTMLInputElement> {
  setImage: (image: File[]) => void;
}

export function Control(props: ControlProps) {
  const { onFilesSelected, multiple, id } = useFileInput();

  function handleFilesSelected(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files?.length) {
      return;
    }

    const files = Array.from(event.target.files);
    props.setImage(files);
    onFilesSelected(files);
  }

  return (
    <input
      id={id}
      type="file"
      className="sr-only"
      onChange={handleFilesSelected}
      multiple={multiple}
      {...props}
    />
  );
}
