import * as Input from "../../components/Form/Input";
import { Button } from "../../components/Button";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useLoading } from "../../store/useLoading";
import api from "../../service/api";

type Props = {
  setCurrentTab: (value: string) => void;
};

export function CreateCategory({ setCurrentTab }: Props) {
  const setLoading = useLoading((store) => store.setLoading);
  // const loading = useLoading((store) => store.isLoading);

  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("");

  function handleCancel() {
    setDescription("");
    setCurrentTab("tab1");
  }

  const handleCreateCategory = useCallback(async () => {
    if (description.length === 0) {
      toast.error("Preencha todos os campos!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      return;
    }
    setLoading(true);

    try {
      await api.post("/admin/category", { name: description, icon });
      toast.success("Categoria cadastrada com sucesso", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      toast.error("Ocorreu um erro tente novamente", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setDescription("");
    } finally {
      setLoading(false);
    }
  }, [description, setLoading]);

  return (
    <>
      <div className="mt-6 flex flex-col">
        <div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center">
          <div className="flex flex-col gap-1">
            <h2 className="text-lg font-medium text-zinc-900 dark:text-white">
              Informações da categoria
            </h2>
            <span className="text-sm text-zinc-500 dark:text-zinc-400">
              Cadastre suas categorias
            </span>
          </div>
        </div>
      </div>

      <form
        id="categories"
        className="mt-6 flex w-full flex-col gap-5 divide-y divide-zinc-200 dark:divide-zinc-800"
      >
        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="name"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Nome
          </label>
          <div className="flex gap-3">
            <Input.Root>
              <Input.Control
                id="description"
                name="description"
                placeholder="Nome"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Input.Root>
          </div>
        </div>
        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="name"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Nome do ícone
          </label>
          <div className="flex gap-3">
            <Input.Root>
              <Input.Control
                id="icon"
                name="icon"
                placeholder="Nome do ícone"
                value={icon}
                onChange={(e) => {
                  setIcon(e.target.value);
                }}
              />
            </Input.Root>
          </div>
        </div>

        <div className="flex items-center justify-end gap-2 pt-5">
          <Button type="button" variant="outline" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            type="button"
            form="categories"
            variant="primary"
            onClick={handleCreateCategory}
          >
            Salvar
          </Button>
        </div>
      </form>
    </>
  );
}
