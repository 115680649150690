import * as Input from "../../components/Form/Input";
import { Button } from "../../components/Button";
import { Eye, Smartphone } from "lucide-react";
import api from "../../service/api";
import { useCallback, useEffect, useState } from "react";
import { useLoading } from "../../store/useLoading";
import { useStore } from "../../store";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { EmptyList } from "../../components/EmptyList";
import { IClient } from "../../@types/system";
import DialogC from "../../components/DialogC";
import { maskPhone } from "../../utils/masks";
import { applyPhoneMask, convertStatusInvite } from "../../utils/helpers";

export function ListClients() {
  const loading = useLoading((store) => store.isLoading);
  const setIsLoading = useLoading((store) => store.setLoading);
  const setLoading = useLoading((store) => store.setLoading);
  const clients = useStore((store) => store.clients);
  const setClients = useStore((store) => store.setClients);

  const [open, setOpen] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [client, setClient] = useState({} as IClient);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");

  function handleShowAction(client: IClient) {
    setShowClient(true);
    setClient(client);

    setName(client.name);
    setEmail(client.email);
    setPhone(client.phone);
    setStatus(client.status);
  }

  function handleSaveClient(e: any) {
    e.preventDefault();
    setOpen(true);
  }

  const handleConfirmClient = useCallback(
    async (e: any) => {
      e.preventDefault();
      setIsLoading(true);

      try {
        await api.put(`/admin/enterprise/${client.id}`, {
          ...client,
        });

        toast.success("Cliente atualizado com sucesso", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setName("")
        setEmail("")
        setPhone("")
        setStatus("")

        setShowClient(false);
        setEditClient(false);
        setOpen(false);
      } catch (error) {
        toast.error(
          "Ocorreu um erro ao tentar atualizar o cliente, tente novamente",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      name,
      email,
      phone,
    ]
  );

  function handleCancel() {
    setShowClient(false);
    setEditClient(false);
  }

  async function getData() {
    setLoading(true);
    try {
      const res = await api.get("/admin/invite");
      setClients(res.data);
    } catch (error) {
      toast.error("Erro ao tentar carregar os dados, recarregue a página", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading && <Loading />}
      {!loading && clients.length === 0 && <EmptyList />}
      {!loading && !showClient && !editClient && clients.length > 0 && (
        <ul
          role="list"
          className="divide-y divide-gray-100  dark:divide-zinc-800"
        >
          {clients.map((client) => (
            <li
              key={client.id}
              className="flex justify-between gap-x-6 py-5"
            >
              <div className="flex min-w-0 gap-x-4">
                {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={client.imageUrl} alt="" /> */}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900  dark:text-white">
                    {client.name}
                  </p>
                  <div className="flex gap-x-4">
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-zinc-400">
                      E-mail: {client.email}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-zinc-400">
                      Telefone: {applyPhoneMask(client.phone)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="shrink-0 sm:flex sm:items-center">
                {client.status === 'EXPIRED' &&
                  <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">Expirado</span>
                }
                {client.status === 'ACCEPTED' &&
                  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Aceito</span>
                }
                {client.status === 'SEND' &&
                  <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">Enviado</span>
                }
                <Button
                  variant="ghost"
                  onClick={() => handleShowAction(client)}
                >
                  <Eye className="h-4 w-4 text-zinc-400" strokeWidth={3} />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      )}
      {(showClient || editClient) && (
        <>
          <form
            id="clients"
            className="mt-6 flex w-full flex-col gap-5 divide-y divide-zinc-200 dark:divide-zinc-800"
          >
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Status
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="status"
                    type="text"
                    name="status"
                    placeholder="Status"
                    value={convertStatusInvite(status)}
                    disabled={showClient}
                    onChange={(e) => setStatus(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Nome
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Nome"
                    value={name}
                    disabled={showClient}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                E-mail
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="email"
                    type="text"
                    name="email"
                    placeholder="Nome"
                    value={email}
                    disabled={showClient}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="phone"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Telefone para contato
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Prefix>
                    <Smartphone className="h-5 w-5 text-zinc-500" />
                  </Input.Prefix>
                  <Input.Control
                    id="phone"
                    type="text"
                    name="phone"
                    placeholder="Telefone"
                    value={phone !== null ? maskPhone(phone) : phone}
                    disabled={showClient}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="flex items-center justify-end gap-2 pt-5">
              <Button type="button" variant="outline" onClick={handleCancel}>
                {editClient ? "Cancelar" : "Voltar"}
              </Button>
              {editClient && (
                <Button
                  type="submit"
                  form="clients"
                  variant="primary"
                  onClick={handleSaveClient}
                >
                  Salvar
                </Button>
              )}
            </div>
          </form>
          <DialogC
            open={open}
            setOpen={setOpen}
            onClick={() => handleConfirmClient}
            text={`Confira todos os dados antes da salvar edição do cliente. `}
            title="Tem certeza que deseja editar esse cliente?"
          />
        </>
      )}
    </>
  );
}
