import * as Input from "../../components/Form/Input";
import { Mail, Smartphone } from "lucide-react";
import { Button } from "../../components/Button";
import { useCallback, useState } from "react";
import { useLoading } from "../../store/useLoading";
import api from "../../service/api";
import { toast } from "react-toastify";
import { maskPhone } from "../../utils/masks";
import { Loading } from "../../components/Loading";
import { useTabContext } from ".";

export interface CreateClientProps {}

export function CreateClient() {
  const { setCurrentTab } = useTabContext();
  const loading = useLoading((store) => store.isLoading);
  const setIsLoading = useLoading((store) => store.setLoading);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleCreateClient = useCallback(
    async (e: any) => {
      e.preventDefault();
      setIsLoading(true);

      if (!name || !email || !phone) {
        return toast.info("Atenção! Preencha todos os campos", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      try {
        await api.post("/admin/invite", {
          name,
          phone,
          email,
        });

        toast.success("Convite enviado com sucesso.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setName("");
        setPhone("");
        setEmail("");
        setCurrentTab("tab1");
      } catch (error: any) {
        toast.error(
          error.response.data.message || "Erro ao tentar enviar o convite.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [name, phone, email]
  );

  function handleCancel() {
    setName("");
    setPhone("");
    setEmail("");
    setCurrentTab("tab1");
  }

  return (
    <>
      <div className="mt-6 flex flex-col">
        <div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center">
          <div className="flex flex-col gap-1">
            <h2 className="text-lg font-medium text-zinc-900 dark:text-white">
              Área para envio de convites
            </h2>
            <span className="text-sm text-zinc-500 dark:text-zinc-400">
              Informe os dados do convidado
            </span>
          </div>
        </div>
      </div>
      
      {loading && <Loading />}
      {!loading &&
        <form
          id="clients"
          className="mt-6 flex w-full flex-col gap-5 divide-y divide-zinc-200 dark:divide-zinc-800"
        >
          <div className="grid gap-3 pt-5 lg:grid-cols-form">
            <label
              htmlFor="document"
              className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
            >
              Nome Completo
            </label>
            <div className="flex gap-3">
              <Input.Root>
                <Input.Control
                  name="first_name"
                  id="first_name"
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Input.Root>
            </div>
          </div>

          <div className="grid gap-3 pt-5 lg:grid-cols-form">
            <label
              htmlFor="phone"
              className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
            >
              Telefone para contato
            </label>
            <div className="flex gap-3">
              <Input.Root>
                <Input.Prefix>
                  <Smartphone className="h-5 w-5 text-zinc-500" />
                </Input.Prefix>
                <Input.Control
                  id="phone"
                  type="text"
                  name="phone"
                  placeholder="Telefone"
                  value={maskPhone(phone)}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Input.Root>
            </div>
          </div>

          <div className="grid gap-3 pt-5 lg:grid-cols-form">
            <label
              htmlFor="email"
              className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
            >
              E-mail de acesso
            </label>
            <div className="flex gap-3">
              <Input.Root>
                <Input.Prefix>
                  <Mail className="h-5 w-5 text-zinc-500" />
                </Input.Prefix>
                <Input.Control
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Seu melhor e-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Input.Root>
            </div>
          </div>

          <div className="flex items-center justify-end gap-2 pt-5">
            <Button 
              type="button" 
              variant="outline"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              form="clients"
              variant="primary"
              onClick={handleCreateClient}
            >
              Salvar
            </Button>
          </div>
        </form>
      }
    </>
  );
}
