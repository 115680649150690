import { ChevronDown } from 'lucide-react'
import { ElementType } from 'react'
import { Link } from "react-router-dom";

interface NavItemProps {
  title: string
  icon: ElementType
  link: string
  isSelected?: boolean
  setCurrentTab: (t: string) => void
}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export function NavItem({ title, icon: Icon, link="/", isSelected=false, setCurrentTab}: NavItemProps) {
  return (
    <Link
      to={link}
      onClick={()=>setCurrentTab(title)}
      className={classNames(isSelected ? "bg-violet-50 dark:bg-zinc-800" : "" ,"group flex items-center gap-3 rounded px-3 py-2 outline-none hover:bg-violet-50 focus-visible:ring-2 focus-visible:ring-violet-500 dark:hover:bg-zinc-800")}
      
    >
      <Icon className={classNames(isSelected ? "text-violet-500" : "text-zinc-500" ,"h-5 w-5 flex-shrink-0 ")} />
      <span className={classNames(isSelected ? "text-violet-500 dark:text-violet-300" : "text-zinc-700 dark:text-zinc-100", "font-medium group-hover:text-violet-500  dark:group-hover:text-violet-300")}>
        {title}
      </span>
      <ChevronDown className="ml-auto h-5 w-5 text-zinc-400 dark:text-zinc-600" />
    </Link>
  )
}
