import { ToastContainer } from "react-toastify";
import { Router } from "./routes";
import AppProvider from "./hook";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useStore } from "./store";

export function App() {
  const loadStates = useStore((store) => store.loadStates);
  const user = useStore((store) => store.user);

  useEffect(() => {
    if (user.id) {
      loadStates();
    }
  }, [user]);

  return (
    <div className="dark:bg-zinc-900 min-h-screen">
      <AppProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          containerId="toast"
        />
        <Router />
      </AppProvider>
    </div>
  );
}
