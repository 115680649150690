/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  // useEffect,\
} from "react";
import { toast } from "react-toastify";
import { IUser } from "../@types/system";
import api from "../service/api";
import { useLoading } from "../store/useLoading";
import { useStore } from "../store";

interface AuthState {
  token: string;
  user: IUser;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContext {
  user: IUser;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContext>({} as AuthContext);

const AuthProvider = ({ children }: any) => {
  const setLoading = useLoading((state) => state.setLoading);
  const setUser = useStore((state) => state.setUser);
  const user = useStore((state) => state.user);
  const setToken = useStore((state) => state.setToken);
  const clearLogin = useStore((state) => state.clearLogin);

  const [data, setData] = useState<AuthState>(() => {
    const tokenLoc = localStorage.getItem("@YazControl:token");
    const userLoc = localStorage.getItem("@YazControl:user");

    if (tokenLoc && userLoc) {
      api.defaults.headers.common["Authorization"] = `Bearer ${tokenLoc}`;
      setToken(tokenLoc);
      setUser(JSON.parse(userLoc));

      return { token: tokenLoc, user: JSON.parse(userLoc) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    setLoading(true);

    if (email === "" || password === "") {
      toast.error("Todos os campos são obrigatorios", {
        containerId: "toast",
      });
      setLoading(false);
      return;
    }

    try {
      const response = await api.post("/sessions", {
        email,
        password,
      });

      const { token, user } = response.data;

      if (user.user_type === "USER") {
        toast.error("Este usuário não tem permissões de administrador", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        return;
      }

      setUser(user);
      setToken(token);
      localStorage.setItem("@YazControl:token", token);
      localStorage.setItem("@YazControl:user", JSON.stringify(user));

      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      setData({ token, user });
      setLoading(false);
    } catch (e: any) {
      setLoading(false);

      // console.log(e.response.status);

      if (e.response.status === 400) {
        toast.error("E-mail ou senha não conferem", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return;
      }
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@YazControl:token");
    localStorage.removeItem("@YazControl:user");

    clearLogin();

    setData({} as AuthState);
  }, []);

  const verifyToken = useCallback(async () => {
    if (user.id) {
      api
        .get(`/user/${user.id}`)
        .then((res) => {
          if (res.status !== 200) {
            signOut();
          }
        })
        .catch((error) => {
          console.log("ERROR", error);
          signOut();
        });
    } else {
      signOut();
    }
  }, [user, signOut]);

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContext {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

// eslint-disable-next-line react-refresh/only-export-components
export { AuthProvider, useAuth };
