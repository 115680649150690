// import { useEffect } from "react";
// import { IClient } from "../../@types/system";
import * as Input from "../../components/Form/Input";
import { Button } from "../../components/Button";
import { Eye } from "lucide-react";
import { useLoading } from "../../store/useLoading";
import { useStore } from "../../store";
import { toast } from "react-toastify";
import api from "../../service/api";
import { useEffect, useState } from "react";
import { Loading } from "../../components/Loading";
import { EmptyList } from "../../components/EmptyList";
import { IUser } from "../../@types/system";
import {
  applyPhoneMask,
  convertMaritalTypes,
  convertStatusInvite,
  formatDate,
  profileType,
} from "../../utils/helpers";

export function ListUsers() {
  const loading = useLoading((store) => store.isLoading);
  const setLoading = useLoading((store) => store.setLoading);
  const users = useStore((store) => store.users);
  const user = useStore((store) => store.user);
  const setUsers = useStore((store) => store.setUsers);

  const [showClient, setShowClient] = useState(false);
  const [client, setClient] = useState({} as IUser);

  async function getData() {
    setLoading(true);
    try {
      const res = await api.get(`admin/users`);

      setUsers(res.data);
    } catch (error) {
      toast.error("Erro ao tentar carregar os dados, recarregue a página", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  function handleShowAction(user: IUser) {
    setShowClient(true);
    setClient(user);
  }
  function handleCancel() {
    setShowClient(false);
  }

  async function handleUpdateUser(id: string, action: string) {
    setLoading(true);
    try {
      await api.patch("/admin/users", {
        id,
        situation: action,
      });
      toast.success("Usuário atualizado com sucesso", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getData();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao tentar atualizar os dados, recarregue a página", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <Loading />}
      {!loading && users.length === 0 && <EmptyList />}
      {!loading && !showClient && users.length > 0 && (
        <ul
          role="list"
          className="divide-y divide-gray-100  dark:divide-zinc-800"
        >
          {users.map((person) => (
            <li key={person.id} className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900  dark:text-white">
                    {person.name +
                      " " +
                      person.last_name +
                      " - " +
                      person.user_type}
                  </p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-zinc-400">
                    {person.email}
                  </p>
                </div>
              </div>
              <div className="shrink-0 sm:flex sm:items-center gap-x-1">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => handleShowAction(person)}
                >
                  <Eye className="h-4 w-4 text-zinc-400" strokeWidth={3} />
                </Button>

                {(person.situation === "WAITING" ||
                  person.situation === "REJECTED") && (
                  <Button
                    type="button"
                    variant="primary"
                    className="h-8 py-0"
                    onClick={() => handleUpdateUser(person.id, "ACCEPTED")}
                  >
                    Aceitar
                  </Button>
                )}

                {(person.situation === "WAITING" ||
                  person.situation === "ACCEPTED") && (
                  <Button
                    type="button"
                    variant="primary"
                    className="bg-orange-500 hover:bg-orange-600 transition duration-200 ease-in-out h-8 py-0"
                    onClick={() => handleUpdateUser(person.id, "REJECTED")}
                  >
                    {person.situation === "WAITING" ? "Rejeitar" : "Bloquear"}
                  </Button>
                )}
                {(person.situation === "WAITING" ||
                  person.situation === "REJECTED") && (
                  <Button
                    type="button"
                    variant="primary"
                    className="bg-red-500 hover:bg-red-600 transition duration-200 ease-in-out h-8 py-0"
                    onClick={() => handleUpdateUser(person.id, "DELETED")}
                  >
                    Deletar
                  </Button>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
      {showClient && (
        <>
          <form
            id="clients"
            className="mt-6 flex w-full flex-col gap-5 divide-y divide-zinc-200 dark:divide-zinc-800"
          >
            <div className="flex items-center justify-end gap-2 pt-5">
              <Button type="button" variant="outline" onClick={handleCancel}>
                Voltar
              </Button>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Nome
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Nome"
                    value={`${user.name} ${user.last_name}`}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                E-mail
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="email"
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    value={client.email}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Telefone
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="phone"
                    type="text"
                    name="phone"
                    placeholder="Telefone"
                    value={applyPhoneMask(client.phone)}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Situação
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="situation"
                    type="text"
                    name="situation"
                    placeholder="Situação"
                    value={convertStatusInvite(client.situation)}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Nick
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="nick_name"
                    type="text"
                    name="nick_name"
                    placeholder="Nick"
                    value={client.nick_name}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Cidade
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="city"
                    type="text"
                    name="city"
                    placeholder="Cidade"
                    value={client.city}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Estado
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="state"
                    type="text"
                    name="state"
                    placeholder="Estado"
                    value={client.state}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Estado civil
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="marital"
                    type="text"
                    name="marital"
                    placeholder="Estado civil"
                    value={convertMaritalTypes(client.marital_status)}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Perfil
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="profile_type"
                    type="text"
                    name="profile_type"
                    placeholder="Estado civil"
                    value={profileType(client.profile_type)}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            {client.marital_status === "MARRIED" && (
              <div className="grid gap-3 pt-5 lg:grid-cols-form">
                <label
                  htmlFor="document"
                  className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
                >
                  Início de relacionamento
                </label>
                <div className="flex gap-3">
                  <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                    <Input.Control
                      id="marital_initial_date"
                      type="text"
                      name="marital_initial_date"
                      placeholder="Data de casamento"
                      value={formatDate(client.marital_initial_date)}
                      disabled={showClient}
                    />
                  </Input.Root>
                </div>
              </div>
            )}
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Filhos
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="children"
                    type="text"
                    name="children"
                    placeholder="Filhos"
                    value={client.children}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                É de maior?
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="legal_age"
                    type="text"
                    name="legal_age"
                    placeholder="Estado civil"
                    value={client.legal_age ? "Sim" : "Não"}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Data de cadastro
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="created_at"
                    type="text"
                    name="created_at"
                    placeholder="Data de cadastro"
                    value={formatDate(client.created_at)}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Última atualização
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="updated_at"
                    type="text"
                    name="updated_at"
                    placeholder="Última atualização"
                    value={formatDate(client.updated_at)}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="document"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Termos de uso
              </label>
              <div className="flex gap-3">
                <Input.Root className={showClient ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="terms"
                    type="text"
                    name="terms"
                    placeholder="Data de cadastro"
                    value={client.accepted_terms ? "Aceito" : "Não aceito"}
                    disabled={showClient}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="flex items-center justify-end gap-2 pt-5">
              <Button type="button" variant="outline" onClick={handleCancel}>
                Voltar
              </Button>
            </div>
          </form>
        </>
      )}
    </>
  );
}
