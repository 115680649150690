import { useEffect } from "react";
import { useTab } from "../../store/useTab";
import { CategoriesTabs } from "./CategoriesTabs";
export function Categories() {
  const setCurrentTab = useTab((store) => store.setTab);

  useEffect(() => {
    setCurrentTab("Categorias");
  }, []);

  return (
    <>
      <h1 className="text-3xl font-medium text-zinc-900 dark:text-zinc-100">
        Categorias
      </h1>
      <CategoriesTabs />
    </>
  );
}
