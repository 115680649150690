import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { SignIn } from "../pages/SignIn";
import { Layout } from "../pages/Layout";
import { Clients } from "../pages/Clients/index";
import { Coupons } from "../pages/Coupons";
import { Categories } from "../pages/Categories";
import { Orders } from "../pages/Orders";
import { Users } from "../pages/Users";
import { useStore } from "../store";
import { Home } from "../pages/Home";
import { Dashboard } from "../pages/Dashboard";

export function Router() {
  const user = useStore((store) => store.user);

  function RequireAuth({ children }: { children: JSX.Element }) {
    const location = useLocation();

    if (!user.id) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  }

  function RequireSuperUSer({ children }: { children: JSX.Element }) {
    const location = useLocation();

    if (user.user_type !== "SUPERADMIN") {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route
          path="/home"
          element={
            <RequireAuth>
              <Layout>
                <Home />
              </Layout>
            </RequireAuth>
          }
        />
        <Route
          path="/invitations"
          element={
            <RequireAuth>
              <RequireSuperUSer>
                <Layout>
                  <Clients />
                </Layout>
              </RequireSuperUSer>
            </RequireAuth>
          }
        />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Layout>
                <Dashboard />
              </Layout>
            </RequireAuth>
          }
        />
        <Route
          path="/coupons"
          element={
            <RequireAuth>
              <Layout>
                <Coupons />
              </Layout>
            </RequireAuth>
          }
        />
        <Route
          path="/categories"
          element={
            <RequireAuth>
              <RequireSuperUSer>
                <Layout>
                  <Categories />
                </Layout>
              </RequireSuperUSer>
            </RequireAuth>
          }
        />
        <Route
          path="/orders"
          element={
            <RequireAuth>
              <RequireSuperUSer>
                <Layout>
                  <Orders />
                </Layout>
              </RequireSuperUSer>
            </RequireAuth>
          }
        />
        <Route
          path="/users"
          element={
            <RequireAuth>
              <Layout>
                <Users />
              </Layout>
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
