function convertStatusInvite(status: string) {
  const AllStatus: any = {
    SEND: "Enviado",
    EXPIRED: "Expirado",
    ACCEPTED: "Aceito"
  }

  return AllStatus[status]
}

function profileType(profile: string) {
  const AllStatus: any = {
    MEN: "Homem",
    WOMAN: "Mulher",
    COUPLEMxW: "Casal (HxM)",
    COUPLEMxM: "Casal (HxH)",
    COUPLEWxW: "Casal (MxM)",
  }

  return AllStatus[profile] || profile
}

function convertMaritalTypes(type: string) {
  const MaritalTypes: any = {
    SINGLE: "Solteiro",
    MARRIED: "Casado",
    FALLING_IN_LOVE: "Namorando",
    STABLE_UNION: "União Estável",
    DIVORCED: "Divorciado",
    WIDOWER: "Viúvo"
  }

  return MaritalTypes[type]
}

function formatDate(timestamp: string, showTime = false) {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  if (showTime) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${formattedDate} ${hours}:${minutes}:${seconds}`;
  }

  return formattedDate;
}

function applyPhoneMask(phone: string) {
  // Remove todos os caracteres que não são dígitos
  phone = phone.replace(/\D/g, '');

  // Aplica a máscara apropriada com base no comprimento do número
  if (phone.length === 11) {
      // Formato para números com 11 dígitos: (00) 00000-0000
      phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  } else if (phone.length === 10) {
      // Formato para números com 10 dígitos: (00) 0000-0000
      phone = phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }

  return phone;
}

export { 
  convertStatusInvite, 
  convertMaritalTypes,
  formatDate,
  applyPhoneMask,
  profileType
}