// import { ICategory } from "../../@types/system";
import { Button } from "../../components/Button";
import { Eye, Pencil, Trash2 } from "lucide-react";
import { useLoading } from "../../store/useLoading";
import { useStore } from "../../store";
import { toast } from "react-toastify";
import api from "../../service/api";
import { useEffect } from "react";
import { Loading } from "../../components/Loading";
import { EmptyList } from "../../components/EmptyList";

export interface ListProductsProps {}

export function ListCategories() {
  const loading = useLoading((store) => store.isLoading);
  const setLoading = useLoading((store) => store.setLoading);
  const categories = useStore((store) => store.categories);
  const setCategories = useStore((store) => store.setCategories);

  async function getData() {
    setLoading(true);
    try {
      const res = await api.get("/system/categories");
      setCategories(res.data);
    } catch (error) {
      toast.error("Erro ao tentar carregar os dados, recarregue a página", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading && <Loading />}
      {!loading && categories.length === 0 && <EmptyList />}
      {!loading && categories.length > 0 && (
        <ul
          role="list"
          className="divide-y divide-zinc-100  dark:divide-zinc-800"
        >
          {categories.map((category) => (
            <li key={category.id} className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-zinc-900  dark:text-white">
                    {category.name}
                  </p>
                </div>
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-regular leading-6 text-zinc-900  dark:text-white">
                    {category.icon}
                  </p>
                </div>
              </div>
              <div className="shrink-0 sm:flex sm:items-center">
                <Button variant="ghost">
                  <Eye className="h-4 w-4 text-zinc-400" strokeWidth={3} />
                </Button>
                <Button variant="ghost">
                  <Pencil className="h-4 w-4 text-zinc-400" strokeWidth={3} />
                </Button>
                <Button variant="ghost">
                  <Trash2 className="h-4 w-4 text-zinc-400" strokeWidth={3} />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}
