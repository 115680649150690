import { create } from "zustand";

export interface useTabProps {
  tab: string;

  setTab: (value: string) => void;
}

export const useTab = create<useTabProps>((set) => {
  return {
    tab: "Home",

    setTab: (tab) => set({ tab }),
  };
});
