
import { ListOrders } from './ListOrders'
export function Orders() {
  
  return (
    <>
      <h1 className="text-3xl font-medium text-zinc-900 dark:text-zinc-100 ">
        Pedidos
      </h1>
      <div className='bg-zinc-100  dark:bg-zinc-800 h-px mt-4' />
      <ListOrders />
    </>
  )
}
