import { LogOut } from "lucide-react";
import { Button } from "../Button";
import { useStore } from "../../store";
import { useAuth } from "../../hook/auth";
import { useNavigate } from "react-router-dom";

export interface ProfileProps {}

export function Profile() {
  const user = useStore((store) => store.user);
  const { signOut } = useAuth();
  const navigate = useNavigate();

  function handleSignOut() {
    signOut();
    navigate("/");
  }
  return (
    <div className="flex items-center gap-3">
      {user.profile_url && (
        <img src={user.profile_url} className="h-10 w-10 rounded-full" alt="" />
      )}
      <div className="flex flex-col">
        <span className="block text-sm font-semibold text-zinc-700 dark:text-zinc-100">
          {user?.name}
        </span>
        <span className="block text-sm text-zinc-500 dark:text-zinc-400">
          {user.email}
        </span>
      </div>
      <Button variant="ghost" className="ml-auto" onClick={handleSignOut}>
        <LogOut className="h-5 w-5 text-zinc-500 dark:text-zinc-400" />
      </Button>
    </div>
  );
}
