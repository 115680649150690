import { create } from 'zustand';

export interface useLoadingProps {
  isLoading: boolean;

  setLoading: (loading: boolean) => void;
}

export const useLoading = create<useLoadingProps>((set) => {
  return {
    isLoading: true,

    setLoading: (loading: boolean) => set({ isLoading: loading }),
  };
});
