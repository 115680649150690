import { UsersTabs } from './UsersTabs'
export function Users() {
  
  return (
    <>
      <h1 className="text-3xl font-medium text-zinc-900 dark:text-zinc-100">
        Clientes
      </h1>
      <UsersTabs />
    </>
  )
}
