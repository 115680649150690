import * as Input from "../../components/Form/Input";
import * as FileInput from "../../components/Form/FileInput";
import * as Select from "../../components/Form/Select";
import { Button } from "../../components/Button";
import { Textarea } from "../../components/Form/Textarea";
import Toggle from "../../components/Form/Toggle";
import { useState } from "react";
import { useStore } from "../../store";
import DialogC from "../../components/DialogC";
import api from "../../service/api";
import { useTabContext } from ".";
import { toast } from "react-toastify";
import { formatMoney } from "../../utils/formatMoney";

export function CreateProduct() {
  const categories = useStore((store) => store.categories);
  const { setCurrentTab } = useTabContext();

  const user = useStore((store) => store.user);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [expires, setExpires] = useState("");
  const [emphasis, setEmphasis] = useState(false);
  const [hotter, setHotter] = useState(false);
  const [expires_reservation, setExpiresReservation] = useState("");
  const [credits, setCredits] = useState(1);
  const [rules, setRules] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [discounted_price, setDiscountedPrice] = useState("");
  const [category_id, setCategoryId] = useState("");
  const [image, setImage] = useState([] as File[]);

  function handleConfirmCreate(e: React.FormEvent<HTMLInputElement>) {
    e.preventDefault();
    setCredits(1 + (emphasis ? 1 : 0) + (hotter ? 1 : 0));
    setOpen(true);
  }

  async function handleCreateCoupom() {
    if (
      title === "" ||
      description === "" ||
      code === "" ||
      expires === "" ||
      credits === 0 ||
      image.length === 0 ||
      rules === "" ||
      quantity === "" ||
      price === "" ||
      discounted_price === "" ||
      // user.enterprise.id === undefined ||
      user.id === undefined ||
      category_id === ""
    ) {
      toast.error("Preencha todos os campos obrigatórios", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("code", code);
    formData.append("expires", expires);
    formData.append("emphasis", emphasis ? "true" : "false");
    formData.append("hotter", hotter ? "true" : "false");
    formData.append("expires_reservation", expires_reservation);
    formData.append("credits", `${credits}`);
    formData.append("rules", `${rules}`);
    formData.append("category_id", category_id);
    formData.append("quantity", `${quantity}`);
    formData.append("user_id", user.id);
    formData.append("price", price.replace(/\D/g, ""));
    formData.append("discounted_price", discounted_price.replace(/\D/g, ""));
    formData.append(
      "enterprise_id",
      user.id
        ? user.id
        : "dd925538-7466-481c-835d-321a81285503"
    );
    formData.append("image", image[0]);

    try {
      const res = await api.post("/coupon", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        toast.success("Cupom cadastrado com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTitle("");
        setDescription("");
        setCode("");
        setExpires("");
        setEmphasis(false);
        setHotter(false);
        setExpiresReservation("");
        setCredits(1);
        setRules("");
        setQuantity("");
        setPrice("");
        setDiscountedPrice("");
        setCategoryId("");

        setOpen(false);
        setCurrentTab("tab1");
      }
    } catch (err: any) {
      if (err.response.data.erro === "Insufficient credits!") {
        toast.error(
          "Saldo insuficiente, recarrega para completar a operação!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }
      toast.error(
        "Ocorreu um erro ao tentar cadastrar o cupom novamente, tente novamente",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      console.log(err);
    }
  }

  return (
    <>
      <div className="mt-6 flex flex-col">
        <div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center">
          <div className="flex flex-col gap-1">
            <h2 className="text-lg font-medium text-zinc-900 dark:text-white">
              Informações do produto
            </h2>
            <span className="text-sm text-zinc-500 dark:text-zinc-400">
              Cadastre seus produtos
            </span>
          </div>

          <div className="flex items-center gap-2">
            <Button type="button" variant="outline">
              Cancelar
            </Button>
            <Button
              type="submit"
              form="products"
              variant="primary"
              onClick={() => handleConfirmCreate}
            >
              Salvar
            </Button>
          </div>
        </div>
      </div>

      <form
        id="products"
        className="mt- flex w-full flex-col gap-5 divide-y divide-zinc-200 dark:divide-zinc-800"
      >
        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="photo"
            className="flex flex-col text-sm font-medium leading-relaxed text-zinc-700 dark:text-zinc-100"
          >
            Foto do produto
            <span className="text-sm font-normal text-zinc-500 dark:text-zinc-400">
              Faça o upload da foto do produto.
            </span>
          </label>
          <FileInput.Root
            id="photo"
            className="flex flex-col items-center lg:items-start gap-5 lg:flex-row"
            size="24"
            rounded="md"
          >
            <FileInput.ImagePreview />
            <FileInput.Trigger />
            <FileInput.Control accept="image/*" setImage={setImage} />
          </FileInput.Root>
        </div>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="name"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Nome
          </label>
          <div className="flex gap-3">
            <Input.Root>
              <Input.Control
                id="name"
                type="text"
                name="name"
                placeholder="Nome"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Input.Root>
          </div>
        </div>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="description"
            className="flex flex-col text-sm font-medium leading-relaxed text-zinc-700 dark:text-zinc-100"
          >
            Descrição
            <span className="text-sm font-normal text-zinc-500 dark:text-zinc-400">
              Descreva as especificações do produto e seus detalhes.
            </span>
          </label>
          <div className="flex flex-col gap-3">
            <Textarea
              name="description"
              id="description"
              placeholder="Descreva as especificações do produto e seus detalhes"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="rules"
            className="flex flex-col text-sm font-medium leading-relaxed text-zinc-700 dark:text-zinc-100"
          >
            Regras de resgate
            <span className="text-sm font-normal text-zinc-500 dark:text-zinc-400">
              Descreva as regras para que o usuario possa fazer o resgate do
              cupom na sua loja.
            </span>
          </label>
          <div className="flex flex-col gap-3">
            <Textarea
              name="rules"
              id="rules"
              placeholder="Descreva as regras para que o usuario possa fazer o resgate do cupom na sua loja"
              value={rules}
              onChange={(e) => setRules(e.target.value)}
            />
          </div>
        </div>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="emphasis"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Cupom em destaque?
          </label>
          <div className="flex gap-3 items-center">
            <span className="text-sm font-medium text-zinc-700 dark:text-zinc-100">
              Não
            </span>
            <Toggle enabled={emphasis} setEnabled={setEmphasis} />
            <span className="text-sm font-medium text-zinc-700 dark:text-zinc-100">
              Sim
            </span>
          </div>
        </div>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="hotter"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Cupom quente?
          </label>
          <div className="flex gap-3 items-center">
            <span className="text-sm font-medium text-zinc-700 dark:text-zinc-100">
              Não
            </span>
            <Toggle enabled={hotter} setEnabled={setHotter} />
            <span className="text-sm font-medium text-zinc-700 dark:text-zinc-100">
              Sim
            </span>
            {/* {enabled && (
              <Input.Root>
                <Input.Control
                  id="emphasys"
                  type="number"
                  name="emphasys"
                  placeholder="Porcentagem do desconto"
                />
              </Input.Root>
            )} */}
          </div>
        </div>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="cost"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Preço do produto
          </label>
          <div className="flex gap-3">
            <Input.Root>
              <Input.Control
                id="cost"
                type="text"
                name="cost"
                placeholder="Preço do produto"
                value={formatMoney(price)}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Input.Root>
          </div>
        </div>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="cost"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Preço do produto com o cupom
          </label>
          <div className="flex gap-3">
            <Input.Root>
              <Input.Control
                id="cost"
                type="text"
                name="cost"
                placeholder="Preço com o cupom"
                value={formatMoney(discounted_price)}
                onChange={(e) => setDiscountedPrice(e.target.value)}
              />
            </Input.Root>
          </div>
        </div>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="value"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Código
          </label>
          <div className="flex gap-3">
            <Input.Root>
              <Input.Control
                id="value"
                type="text"
                name="value"
                placeholder="Código"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Input.Root>
          </div>
        </div>

        <label className="grid gap-3 pt-5 lg:grid-cols-form">
          <span className="flex flex-col text-sm font-medium leading-relaxed text-zinc-700 dark:text-zinc-100">
            Categoria
          </span>
          <Select.Root name="category" onValueChange={setCategoryId}>
            <Select.Trigger>
              <Select.Value placeholder="Selecione a categoria" />
            </Select.Trigger>

            <Select.Content>
              {categories.map((category) => (
                <Select.Item value={category.id}>
                  <Select.ItemText>{category.name}</Select.ItemText>
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </label>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="value"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Quantidade
          </label>
          <div className="flex gap-3">
            <Input.Root>
              <Input.Control
                id="value"
                type="number"
                name="value"
                placeholder="Quantidade"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Input.Root>
          </div>
        </div>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="value"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Valido até
          </label>
          <div className="flex gap-3">
            <Input.Root>
              <Input.Control
                id="value"
                type="date"
                name="value"
                placeholder="Validade"
                value={expires}
                onChange={(e) => setExpires(e.target.value)}
              />
            </Input.Root>
          </div>
        </div>

        <div className="grid gap-3 pt-5 lg:grid-cols-form">
          <label
            htmlFor="value"
            className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
          >
            Reserva válida por
          </label>
          <div className="flex gap-3">
            <Input.Root>
              <Input.Control
                id="value"
                type="number"
                name="value"
                placeholder="Quantos dias o usuario tem para resgatar o cupom após a reserva?"
                value={expires_reservation}
                onChange={(e) => setExpiresReservation(e.target.value)}
              />
            </Input.Root>
          </div>
        </div>

        <div className="flex items-center justify-end gap-2 pt-5">
          <Button type="button" variant="outline">
            Cancelar
          </Button>
          <Button
            type="submit"
            form="products"
            variant="primary"
            onClick={() =>handleConfirmCreate}
          >
            Salvar
          </Button>
        </div>
      </form>
      <DialogC
        open={open}
        setOpen={setOpen}
        onClick={handleCreateCoupom}
        text={`A criação deste cupom custará ${credits} crédito${
          credits > 1 ? "s" : ""
        } do seu saldo`}
        title="Tem certeza que deseja criar esse cupom?"
      />
    </>
  );
}
