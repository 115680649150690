import { useEffect } from "react";
import { useTab } from "../../store/useTab";

export function Home() {
  const setCurrentTab = useTab((store) => store.setTab);

  useEffect(() => {
    setCurrentTab("Home");
  }, []);
  return (
    <>
      <h1 className="text-3xl font-medium text-zinc-900 dark:text-zinc-100">
        Home
      </h1>
    </>
  );
}
