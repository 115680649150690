import {
  HTMLAttributes,
  createContext,
  useContext,
  useId,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

export interface RootProps extends HTMLAttributes<HTMLDivElement> {
  multiple?: boolean;
  size?: string;
  rounded?: string;
}

interface FileInputContextType {
  id: string;
  files: File[];
  multiple: boolean;
  onFilesSelected: (files: File[]) => void;
  size: string;
  rounded: string;
}

const FileInputContext = createContext({} as FileInputContextType);

export function Root({
  multiple = false,
  id,
  size = "16",
  rounded = "full",
  ...props
}: RootProps) {
  const customId = useId();
  const [files, setFiles] = useState<File[]>([]);

  return (
    <FileInputContext.Provider
      value={{
        id: id ?? customId,
        files,
        multiple,
        onFilesSelected: setFiles,
        size,
        rounded,
      }}
    >
      <div {...props} className={twMerge("group w-full", props.className)} />
    </FileInputContext.Provider>
  );
}

export const useFileInput = () => useContext(FileInputContext);
