import {
  BarChart,
  Users,
  User,
  // ScrollText,
} from "lucide-react";

import { NavItem } from "./NavItem";
import { useTab } from "../../../store/useTab";
import { useStore } from "../../../store";

export interface NavigationProps {}

export function Navigation() {
  const user = useStore((store) => store.user);
  const currentTab = useTab((store) => store.tab);
  const setCurrentTab = useTab((store) => store.setTab);
  return (
    <nav className="flex flex-col gap-0.5">
      {/* <NavItem
        icon={Home}
        title="Home"
        link="/home"
        isSelected={currentTab === "Home"}
        setCurrentTab={setCurrentTab}
      /> */}
      <NavItem
        icon={BarChart}
        title="Dashboard"
        link="/dashboard"
        isSelected={currentTab === "Dashboard"}
        setCurrentTab={setCurrentTab}
      />
      {user.user_type === "SUPERADMIN" && (
        <NavItem
          icon={Users}
          title="Convites"
          link="/invitations"
          isSelected={currentTab === "Convites"}
          setCurrentTab={setCurrentTab}
        />
      )}
      {/* <NavItem
        icon={TicketPercent}
        title="Cupons"
        link="/coupons"
        isSelected={currentTab === "Cupons"}
        setCurrentTab={setCurrentTab}
      /> */}
      {/* {user.user_type === "SUPERADMIN" && (
        <NavItem
          icon={Tag}
          title="Categorias"
          link="/categories"
          isSelected={currentTab === "Categorias"}
          setCurrentTab={setCurrentTab}
        />
      )} */}
      {/* <NavItem
        icon={ScrollText}
        title="Pedidos"
        link="/orders"
        isSelected={currentTab === "Pedidos"}
        setCurrentTab={setCurrentTab}
      /> */}
      <NavItem
        icon={User}
        title="Usuários"
        link="/users"
        isSelected={currentTab === "Usuários"}
        setCurrentTab={setCurrentTab}
      />
    </nav>
  );
}
