"use client";

import { Logo } from "../../components/Logo";
import * as Input from "../../components/Form/Input";
import { Button } from "../../components/Button";
import { useAuth } from "../../hook/auth";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useStore } from "../../store";
import { useNavigate } from "react-router-dom";

export function SignIn() {
  const { signIn } = useAuth();
  const user = useStore((store) => store.user);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      try {
        await signIn({ email, password });
      } catch (error) {
        toast.error("email/senha incorretos!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    },
    [email, password, signIn]
  );

  useEffect(() => {
    if (user.id) {
      navigate("/dashboard");
    }
  }, [user]);

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="flex justify-center">
            <Logo />
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
            Faça o login na sua conta
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Email
              </label>
              <div className="mt-2">
                <Input.Root>
                  <Input.Control
                    name="email"
                    id="email"
                    type="text"
                    placeholder="Nome"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  Senha
                </label>
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-violet-500 hover:text-violet-400"
                  >
                    Esqueci a senha?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <Input.Root>
                  <Input.Control
                    name="password"
                    id="password"
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <div>
              <Button className="w-full h-10" onClick={handleSubmit}>
                Entrar
              </Button>
            </div>
          </form>

          {/* <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <a href="#" className="font-semibold leading-6 text-violet-500 hover:text-violet-400">
              Start a 14 day free trial
            </a>
          </p> */}
        </div>
      </div>
    </>
  );
}
