import { create } from "zustand";
import { ICategory, IClient, IProduct, IState, IUser } from "../@types/system";
import api from "../service/api";

export interface useStateProps {
  token: string | null;
  user: IUser;
  clients: IClient[];
  states: IState[];
  selectState: IState;
  userType: string[];
  products: IProduct[];
  categories: ICategory[];
  users: IUser[];

  setToken: (token: string) => void;
  setUser: (user: IUser) => void;
  setUsers: (user: IUser[]) => void;
  setClients: (user: IClient[]) => void;
  setStates: (states: IState[]) => void;
  setSelectState: (state: IState) => void;
  setProducts: (products: IProduct[]) => void;
  setCategories: (categories: ICategory[]) => void;

  clearLogin: () => void;

  loadStates: () => Promise<void>;
}

export const useStore = create<useStateProps>((set) => {
  return {
    token: null,
    user: {} as IUser,
    clients: [],
    users: [],
    states: [],
    products: [],
    categories: [],
    selectState: {} as IState,
    userType: ["ADMIN", "MANAGER", "USER", "SUPERADMIN", "SELLER"],

    setToken: (token) => set({ token }),
    setUser: (user) => set({ user }),
    setUsers: (users) => set({ users }),
    setClients: (clients) => set({ clients }),
    setStates: (states) => set({ states }),
    setSelectState: (selectState) => set({ selectState }),
    setProducts: (products) => {
      products = products.map((p) => {
        return {
          ...p,
          discounted_priceFormat: (p.discounted_price / 100).toFixed(2),
          priceFormat: (p.price / 100).toFixed(2),
        };
      });
      set({ products });
    },
    setCategories: (categories) => set({ categories }),

    clearLogin: () =>
      set({
        token: null,
        user: {} as IUser,
      }),

    loadStates: async () => {
      const categories = await api.get("/system/categories");
      const states = await api.get("/system/states");
      set({
        states: states.data,
        categories: categories.data,
      });
    },
  };
});
