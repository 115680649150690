// import { IProduct } from "../../@types/system";
import { Button } from "../../components/Button";
import { useLoading } from "../../store/useLoading";
import { formatMoney } from "../../utils/formatMoney";
import { Eye, Pencil, Trash2 } from "lucide-react";
import api from "../../service/api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useStore } from "../../store";
import { Loading } from "../../components/Loading";
import { EmptyList } from "../../components/EmptyList";
import { IProduct } from "../../@types/system";
import * as Input from "../../components/Form/Input";
import * as Select from "../../components/Form/Select";
import { Textarea } from "../../components/Form/Textarea";
// import Toggle from "../../components/Form/Toggle";
import DialogC from "../../components/DialogC";

export interface ListProductsProps {}

export function ListCoupons() {
  const loading = useLoading((store) => store.isLoading);
  const setLoading = useLoading((store) => store.setLoading);
  const products = useStore((store) => store.products);
  const setProducts = useStore((store) => store.setProducts);
  const user = useStore((store) => store.user);

  const categories = useStore((store) => store.categories);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [expires, setExpires] = useState("");
  // const [emphasis, setEmphasis] = useState(false);
  // const [hotter, setHotter] = useState(false);
  const [expires_reservation, setExpiresReservation] = useState(2);
  const [credits, setCredits] = useState(1);
  const [rules, setRules] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [discounted_price, setDiscountedPrice] = useState("");
  const [category_id, setCategoryId] = useState("");

  const [showCoupon, setShowCoupon] = useState(false);
  const [editCoupon, setEditCoupon] = useState(false);
  const [coupon, setCoupon] = useState({} as IProduct);

  function handleShowAction(coupon: IProduct) {
    setShowCoupon(true);
    setCoupon(coupon);
    setTitle(coupon.title);
    setDescription(coupon.description);
    setCode(coupon.code);
    setExpires(coupon.expires.split("T")[0]);
    setExpiresReservation(coupon.expires_reservation);
    setCredits(coupon.credits);
    setRules(coupon.rules);
    setQuantity(`${coupon.quantity}`);
    setPrice(`${coupon.price}`);
    setDiscountedPrice(`${coupon.discounted_price}`);
    setCategoryId(coupon.category_id);
  }

  function handleEditAction(coupon: IProduct) {
    setCoupon(coupon);
    setShowCoupon(false);
    setEditCoupon(true);

    setTitle(coupon.title);
    setDescription(coupon.description);
    setCode(coupon.code);
    setExpires(coupon.expires.split("T")[0]);
    setExpiresReservation(coupon.expires_reservation);
    setCredits(coupon.credits);
    setRules(coupon.rules);
    setQuantity(`${coupon.quantity}`);
    setPrice(`${coupon.price}`);
    setDiscountedPrice(`${coupon.discounted_price}`);
    setCategoryId(coupon.category_id);
  }

  function handleConfirmCreate(e: any) {
    e.preventDefault();
    // if(credits === 3){
    //   setOpen(true);
    //   return
    // }
    // const up = 1 + (emphasis ? 1 : 0) + (hotter ? 1 : 0)
    // if(credits < up){

    // }
    // setCredits(1 + (emphasis ? 1 : 0) + (hotter ? 1 : 0));
    setOpen(true);
  }

  async function handleSaveCoupon() {
    const formData = {
      coupom_id: coupon.id,
      title: title,
      description: description,
      code: code,
      expires: expires,
      emphasis: `${coupon.emphasis}`,
      hotter: `${coupon.hotter}`,
      expires_reservation: `${expires_reservation}`,
      credits: `${credits}`,
      rules: `${rules}`,
      category_id: category_id,
      quantity: `${quantity}`,
      user_id: user.id,
      price: price.replace(/\D/g, ""),
      discounted_price: discounted_price.replace(/\D/g, ""),
      enterprise_id: coupon.enterprise_id,
    };

    try {
      const res = await api.put("/coupon", formData);

      if (res.status === 200) {
        toast.success("Cupom cadastrado com sucesso!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTitle("");
        setDescription("");
        setCode("");
        setExpires("");

        setExpiresReservation(2);
        setCredits(1);
        setRules("");
        setQuantity("");
        setPrice("");
        setDiscountedPrice("");
        setCategoryId("");

        setOpen(false);
        setShowCoupon(false);
        setEditCoupon(false);
      }
    } catch (err: any) {
      if (err.response.data.erro === "Insufficient credits!") {
        toast.error(
          "Saldo insuficiente, recarrega para completar a operação!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        return;
      }
      toast.error(
        "Ocorreu um erro ao tentar cadastrar o cupom novamente, tente novamente",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      console.log(err);
    }
  }

  function handleCancel() {
    setShowCoupon(false);
    setEditCoupon(false);
  }

  async function getData() {
    setLoading(true);
    try {
      const res = await api.get("/coupon");
      setProducts(res.data);
    } catch (error) {
      toast.error("Erro ao tentar carregar os dados, recarregue a página", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading && <Loading />}
      {!loading && products.length === 0 && <EmptyList />}
      {!loading && !showCoupon && !editCoupon && products.length > 0 && (
        <ul
          role="list"
          className="divide-y divide-zinc-100  dark:divide-zinc-800"
        >
          {products.map((product) => (
            <li key={product.id} className="flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <img
                  className="h-12 w-12 flex-none rounded-lg bg-zinc-50"
                  src={product.image_url}
                  alt=""
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-zinc-900  dark:text-white">
                    {product.title}
                  </p>
                  <div className="grid grid-cols-2 gap-3">
                    <p className="mt-1 truncate text-xs leading-5 text-zinc-500 dark:text-zinc-400">
                      <span className="text-md font-semibold">
                        Valor com cupom:{" "}
                      </span>
                      {formatMoney(product.discounted_priceFormat)}
                    </p>
                    <p className="mt-1 truncate text-xs leading-5 text-zinc-500 dark:text-zinc-400">
                      <span className="text-md font-semibold">
                        Valor do produto:{" "}
                      </span>
                      {formatMoney(product.priceFormat)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="shrink-0 sm:flex sm:items-center">
                <Button
                  variant="ghost"
                  onClick={() => handleShowAction(product)}
                >
                  <Eye className="h-4 w-4 text-zinc-400" strokeWidth={3} />
                </Button>
                <Button
                  variant="ghost"
                  onClick={() => handleEditAction(product)}
                >
                  <Pencil className="h-4 w-4 text-zinc-400" strokeWidth={3} />
                </Button>
                <Button variant="ghost">
                  <Trash2 className="h-4 w-4 text-zinc-400" strokeWidth={3} />
                </Button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {(showCoupon || editCoupon) && (
        <>
          <div className="mt-6 flex flex-col">
            <div className="flex flex-col justify-between gap-4 lg:flex-row lg:items-center">
              <div className="flex flex-col gap-1">
                <h2 className="text-lg font-medium text-zinc-900 dark:text-white">
                  {showCoupon ? "Visualizando" : "Editando"} o cupom
                </h2>
              </div>
            </div>
          </div>

          <form
            id="products"
            className="mt- flex w-full flex-col gap-5 divide-y divide-zinc-200 dark:divide-zinc-800"
          >
            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="photo"
                className="flex flex-col text-sm font-medium leading-relaxed text-zinc-700 dark:text-zinc-100"
              >
                Foto do produto
                <span className="text-sm font-normal text-zinc-500 dark:text-zinc-400">
                  Faça o upload da foto do produto.
                </span>
              </label>
              <img
                src={coupon.image_url}
                className="w-32 h-32 rounded-md object-cover"
              />
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="name"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Nome
              </label>
              <div className="flex gap-3">
                <Input.Root className={showCoupon ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="name"
                    type="text"
                    name="name"
                    placeholder="Nome"
                    value={title}
                    disabled={showCoupon}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="description"
                className="flex flex-col text-sm font-medium leading-relaxed text-zinc-700 dark:text-zinc-100"
              >
                Descrição
                <span className="text-sm font-normal text-zinc-500 dark:text-zinc-400">
                  Descreva as especificações do produto e seus detalhes.
                </span>
              </label>
              <div className="flex flex-col gap-3">
                <Textarea
                  name="description"
                  id="description"
                  placeholder="Descreva as especificações do produto e seus detalhes"
                  value={description}
                  disabled={showCoupon}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="rules"
                className="flex flex-col text-sm font-medium leading-relaxed text-zinc-700 dark:text-zinc-100"
              >
                Regras de resgate
                <span className="text-sm font-normal text-zinc-500 dark:text-zinc-400">
                  Descreva as regras para que o usuario possa fazer o resgate do
                  cupom na sua loja.
                </span>
              </label>
              <div className="flex flex-col gap-3">
                <Textarea
                  name="rules"
                  id="rules"
                  placeholder="Descreva as regras para que o usuario possa fazer o resgate do cupom na sua loja"
                  value={rules}
                  disabled={showCoupon}
                  onChange={(e) => setRules(e.target.value)}
                />
              </div>
            </div>

            {/* <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="emphasis"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Cupom em destaque?
              </label>
              <div className="flex gap-3 items-center">
                <span className="text-sm font-medium text-zinc-700 dark:text-zinc-100">
                  Não
                </span>
                <Toggle enabled={emphasis} setEnabled={setEmphasis} />
                <span className="text-sm font-medium text-zinc-700 dark:text-zinc-100">
                  Sim
                </span>
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="hotter"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Cupom quente?
              </label>
              <div className="flex gap-3 items-center">
                <span className="text-sm font-medium text-zinc-700 dark:text-zinc-100">
                  Não
                </span>
                <Toggle enabled={hotter} setEnabled={setHotter} />
                <span className="text-sm font-medium text-zinc-700 dark:text-zinc-100">
                  Sim
                </span>
              </div>
            </div> */}

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="cost"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Preço do produto
              </label>
              <div className="flex gap-3">
                <Input.Root className={showCoupon ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="cost"
                    type="text"
                    name="cost"
                    placeholder="Preço do produto"
                    value={formatMoney(price)}
                    disabled={showCoupon}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="cost"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Preço do produto com o cupom
              </label>
              <div className="flex gap-3">
                <Input.Root className={showCoupon ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="cost"
                    type="text"
                    name="cost"
                    placeholder="Preço com o cupom"
                    disabled={showCoupon}
                    value={formatMoney(discounted_price)}
                    onChange={(e) => setDiscountedPrice(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="value"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Código
              </label>
              <div className="flex gap-3">
                <Input.Root className="bg-zinc-100">
                  <Input.Control
                    id="value"
                    type="text"
                    name="value"
                    placeholder="Código"
                    value={code}
                    disabled={showCoupon || editCoupon}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <label className="grid gap-3 pt-5 lg:grid-cols-form">
              <span className="flex flex-col text-sm font-medium leading-relaxed text-zinc-700 dark:text-zinc-100">
                Categoria
              </span>
              <Select.Root
                name="category"
                onValueChange={setCategoryId}
                defaultValue={category_id}
              >
                <Select.Trigger>
                  <Select.Value placeholder="Selecione a categoria" />
                </Select.Trigger>

                <Select.Content>
                  {categories.map((category) => (
                    <Select.Item value={category.id}>
                      <Select.ItemText>{category.name}</Select.ItemText>
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </label>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="value"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Quantidade
              </label>
              <div className="flex gap-3">
                <Input.Root className={showCoupon ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="value"
                    type="number"
                    name="value"
                    placeholder="Quantidade"
                    value={quantity}
                    disabled={showCoupon}
                    onChange={(e) => setQuantity(Number(e.target.value) as any)}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="value"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Valido até
              </label>
              <div className="flex gap-3">
                <Input.Root className={"bg-zinc-100"}>
                  <Input.Control
                    id="value"
                    type="date"
                    name="value"
                    placeholder="Validade"
                    value={expires}
                    disabled={showCoupon || editCoupon}
                    onChange={(e) => setExpires(e.target.value)}
                  />
                </Input.Root>
              </div>
            </div>

            <div className="grid gap-3 pt-5 lg:grid-cols-form">
              <label
                htmlFor="value"
                className="text-sm font-medium text-zinc-700 dark:text-zinc-100"
              >
                Reserva válida por
              </label>
              <div className="flex gap-3">
                <Input.Root className={showCoupon ? "bg-zinc-100" : ""}>
                  <Input.Control
                    id="value"
                    type="number"
                    name="value"
                    placeholder="Quantos dias o usuario tem para resgatar o cupom após a reserva?"
                    value={expires_reservation}
                    disabled={showCoupon}
                    onChange={(e) =>
                      setExpiresReservation(Number(e.target.value))
                    }
                  />
                </Input.Root>
              </div>
            </div>

            <div className="flex items-center justify-end gap-2 pt-5">
              <Button type="button" variant="outline" onClick={handleCancel}>
                {showCoupon ? "Voltar" : "Cancelar"}
              </Button>
              {editCoupon && (
                <Button
                  type="submit"
                  form="products"
                  variant="primary"
                  onClick={handleConfirmCreate}
                >
                  Salvar
                </Button>
              )}
            </div>
          </form>
          <DialogC
            open={open}
            setOpen={setOpen}
            onClick={handleSaveCoupon}
            text={`Confira todos os dados antes da salvar edição do seu cupom. `}
            title="Tem certeza que deseja editar esse cupom?"
          />
        </>
      )}
    </>
  );
}
